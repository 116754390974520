<template>
  <div>
    <!-- 导航 -->
    <div>
      <van-nav-bar title="邻卷中心" left-text="返回" left-arrow>
        <template #right> </template>
      </van-nav-bar>
    </div>
    <!-- 导航 -->
    <div class="shows"></div>
    <!-- 底部 -->
    <div style="padding: 10px;">
      <div style="display:flex;">
        <div>
        <van-image round width="4rem" height="4rem" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>  
        </div>
        <div style="padding: 10px;">
          <h3>夏婷荣推荐</h3>
          <span>1小时前</span>
        </div>
      </div>
      <div style="padding-top:20px;">
        <p>又来到了这个时候</p>
        <p>又来到了这个时候</p>
        <p>又来到了这个时候</p>
        <p>又来到了这个时候</p>
        <p>又来到了这个时候</p>
      </div>
      <div style="margin-top:10px;">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 130px; height: 130px;border-radius: 10px; ">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 130px; height: 130px;margin: 0 20px;border-radius: 10px;">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 130px; height: 130px;border-radius: 10px;">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 130px; height: 130px;border-radius: 10px;">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 130px; height: 130px;margin: 0 20px;border-radius: 10px;">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style="width: 130px; height: 130px;border-radius: 10px;">
      </div>
      <div style="display: flex;">
        <div >
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 80px; height:80px;border-radius: 10px; ">
        </div>
        <div style="padding: 5px;">
        <div style="display: flex;margin-bottom: 5px;text-overflow:ellipsis; ">
        <img src="https://img01.yzcdn.cn/vant/cat.jpeg" style=" width: 20px; height:20px;">
        <span style="">又来到了这个时候又来到了</span>
        </div>
        <div class="volume">
          <div>
            <h2>19,9</h2>
            <span>卷后价元</span>
          </div>
          <div>
            <h2>19,9</h2>
            <span>卷后价元</span>
          </div>
          <div style="background-color: orange; width: 100px;">
            <h2>19,9</h2>
            <span>卷后价元</span>
          </div>
        </div>
        </div>
      </div>
       <div>
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
      </div>
      <div style="margin-top:15px;">
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
        <p>又来到了这个时</p>
      </div>
      <div class="bottom-s">
        <div style="display: flex;align-items: center;">
        <van-icon name="todo-list-o" />
         <span style="margin-left:10px">保存素材</span>
        </div>
         <div style="display: flex;align-items: center;">
        <van-icon name="comment-o" />
         <span  style="margin-left:10px">复制文案</span>
        </div>
      </div>
    </div>
    <!-- 底部 -->
        <div class="show-s"></div>
  </div>
</template>

<script>
export default {
  name: "demo",
  data() {
    return {};
  },
  created() {},
  components: {},
};
</script>
<style>
.show-s{
  background-color: #f1f1f1;
  height: 30px;
}
.shows{
  background-color: #f1f1f1;
  height: 10px;
}
.volume{
  height: 50px;
    border: 1px solid;
    display: flex;
    width: 340px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.bottom-s{
   font-size: 17px;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    border-top: 1px solid #f1f1f1;
    height: 40px;
    line-height: 40px;
    align-items: center;
}
</style>
