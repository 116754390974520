var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('van-nav-bar',{attrs:{"title":"邻卷中心","left-text":"返回","left-arrow":""},scopedSlots:_vm._u([{key:"right",fn:function(){return undefined},proxy:true}])})],1),_c('div',{staticClass:"shows"}),_c('div',{staticStyle:{"padding":"10px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('van-image',{attrs:{"round":"","width":"4rem","height":"4rem","src":"https://img01.yzcdn.cn/vant/cat.jpeg"}})],1),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"bottom-s"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('van-icon',{attrs:{"name":"todo-list-o"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("保存素材")])],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('van-icon',{attrs:{"name":"comment-o"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("复制文案")])],1)])]),_c('div',{staticClass:"show-s"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"10px"}},[_c('h3',[_vm._v("夏婷荣推荐")]),_c('span',[_vm._v("1小时前")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"20px"}},[_c('p',[_vm._v("又来到了这个时候")]),_c('p',[_vm._v("又来到了这个时候")]),_c('p',[_vm._v("又来到了这个时候")]),_c('p',[_vm._v("又来到了这个时候")]),_c('p',[_vm._v("又来到了这个时候")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('img',{staticStyle:{"width":"130px","height":"130px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('img',{staticStyle:{"width":"130px","height":"130px","margin":"0 20px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('img',{staticStyle:{"width":"130px","height":"130px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('img',{staticStyle:{"width":"130px","height":"130px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('img',{staticStyle:{"width":"130px","height":"130px","margin":"0 20px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('img',{staticStyle:{"width":"130px","height":"130px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('img',{staticStyle:{"width":"80px","height":"80px","border-radius":"10px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}})]),_c('div',{staticStyle:{"padding":"5px"}},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"5px","text-overflow":"ellipsis"}},[_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":"https://img01.yzcdn.cn/vant/cat.jpeg"}}),_c('span',{},[_vm._v("又来到了这个时候又来到了")])]),_c('div',{staticClass:"volume"},[_c('div',[_c('h2',[_vm._v("19,9")]),_c('span',[_vm._v("卷后价元")])]),_c('div',[_c('h2',[_vm._v("19,9")]),_c('span',[_vm._v("卷后价元")])]),_c('div',{staticStyle:{"background-color":"orange","width":"100px"}},[_c('h2',[_vm._v("19,9")]),_c('span',[_vm._v("卷后价元")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"15px"}},[_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")]),_c('p',[_vm._v("又来到了这个时")])])
}]

export { render, staticRenderFns }